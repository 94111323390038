/* eslint-env browser */
import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import fullpageLoading from './loaders/fullpage-loading.html';
import loaderrorParcel from './loaderror-parcel';
import { initChat } from '@awise/chat';
import { BrowserAgent as NewRelicAgent } from '@newrelic/browser-agent/loaders/browser-agent';

console.info(`@awise/root-config loaded with importmap version ${window.AWISE_VERSION}`);

// Import regenerator-runtime for async/await support
import 'regenerator-runtime/runtime';

// Init trackers (Google Analytics, Jitsu, UserGuiding)
import './trackers';

const routes = constructRoutes(microfrontendLayout, {
    errors: {
        default: loaderrorParcel,
    },
    loaders: {
        default: 'Carregando aplicação...',
        fullpage: fullpageLoading,
    },
});

const applications = constructApplications({
    routes,
    loadApp: ({ name }) => System.import(name),
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// Sobrescreve o activeWhen de algumas aplicações que possuem regra de ativação mais complexas e que
// não podem ser expressas no template do single-spa-layout.
applications.find(({ name }) => name === '@quantosobra/system-interface').activeWhen = (location) => {
    return (
        !/^\/a\/[^/]+\/[^/]+\/financial-registers/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/financial\//.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/dashboard/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/home/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/products/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/customers/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/product-returns\/report/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/sales-intents/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/sales\/reports/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/sales\/dashboard/.test(location.pathname) &&
        !/^\/manager/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/integrations/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/financial-movements/.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/cash-flow-statement/.test(location.pathname)
    );
};
applications.find(({ name }) => name === '@awise/sidebar').activeWhen = (location) => {
    return (
        /^\/a\/[^/]+\/[^/]+\//.test(location.pathname) &&
        !/^\/a\/[^/]+\/[^/]+\/(assinar|billings\/detail|client-data|point-of-sale|subscribe)/.test(location.pathname) &&
        !(/^\/a\/[^/]+\/[^/]+\/billings/.test(location.pathname) && location.search.includes('blocked'))
    );
};

applications.forEach(registerApplication);
layoutEngine.activate();

start({ urlRerouteOnly: true });

if (process.env.INTERCOM_APP_ID) {
    initChat(process.env.INTERCOM_APP_ID);
}

const initialLoading = document.getElementById('singlespa-fullpage-loading');
initialLoading?.parentElement?.removeChild(initialLoading);

if (process.env.NODE_ENV === 'production') {
    const agent = new NewRelicAgent({
        init: {
            privacy: {
                cookies_enabled: true,
            },
            ajax: {
                deny_list: ['bam.nr-data.net'],
            },
        },
        loader_config: {
            accountID: '4287853',
            trustKey: '4287853',
            agentID: '1386147039',
            licenseKey: 'NRJS-e27096b40ce00b31163',
            applicationID: '1386147039',
        },
        info: {
            beacon: 'bam.nr-data.net',
            errorBeacon: 'bam.nr-data.net',
            licenseKey: 'NRJS-e27096b40ce00b31163',
            applicationID: '1386147039',
            sa: 1,
        },
    });

    agent.setApplicationVersion(window.AWISE_VERSION);
    agent.setErrorHandler((error) => {
        let shouldIgnore = false;

        if (process.env.JITSU_URL && error?.message?.includes(process.env.JITSU_URL)) {
            // Jitsu
            shouldIgnore = true;
        }
        if (error?.message?.includes("was canceled because it belongs to a 'restartable' Task")) {
            // Ember Concurrency
            shouldIgnore = true;
        }
        if (error?.message?.includes("Cannot read properties of undefined (reading 'points')")) {
            // Google Charts
            shouldIgnore = true;
        }

        return shouldIgnore;
    });
}
